
table tbody tr:nth-child(even) {
  background-color: #e3e3e373 !important;

}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8em;
}

th, td {
  padding: 10px;
}

th {
  color: #f1f1f1;
}

thead, tfoot {
  th {
    background-color: #5e5e5e;
  }
}

