@import "constants";

html, body {
  font-family: "Manrope", "Roboto", "Verdana", sans-serif !important;
  font-size: 13px;
}

div.alert.alert-danger.alert-validation {
  color: rgb(211, 47, 47);
  font-size: 0.8em;
}

fieldset {
  border-radius: 5px;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.8em;
}

.rbc-event-content {
  font-size: 0.8em;
  white-space: normal !important;
}
