//Constants for Arteco Extranet
$verde-arteco: #29deba;
$verde-arteco-light: lighten($verde-arteco, 26);
$azul-oscuro: #26364d;
$azul-electrico: #2445b8;
$azul-electrico-oscuro: #122b81;
$gris-claro: #e4e4e4;
$gris: #c4c4c4;
$gris-oscuro: #817f7f;
$dismissed: #b4b4b4;
$blanco: #fff;
$negro: #000;


//Size
$navbar-size: 4.7rem;


@mixin form-input {
  margin-bottom: 2em;
}

form .MuiGrid-item {
  flex-grow: 1;
}
