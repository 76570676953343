.list {
  .listitem {
    border-bottom: 1px solid lightgray;
  }
}

table.list {
  tr {
    th {
      color: white;
    }
  }
}

/*
.list {
  font-size: 0.9em;


  .listitem {
    padding: 0;
    border-bottom: 1px solid lightgray;

    &:hover {

      div{
        background-color: #c7c7c7 !important;
      }
    }

    div {
      padding: 2px;
    }
  }
}


 */