@import "../../styles/constants";



/*
$breakpoint argument choices:
    -small-mobile
    -mobile-screen
    -tablet-screen
    -laptop
    -pc
*/

// Screens
$small-mobile-screen: 21.875em; // 350px / 16px;
$mobile-screen: 28.125em; // 450px / 16px;
$tablet-screen: 56.25em; // 900px / 16px
$laptop-screen: 64em; // 1024px / 16px;
$pc-screen: 121em; // 1936px /16; more than full hd;


@mixin responsive($breakpoint) {
    @if $breakpoint == small-mobile {
        @media only screen and (max-width: $small-mobile-screen) {
            @content;
        }
    }

    @if $breakpoint == mobile {
        @media only screen and (max-width: $mobile-screen) {
            @content;
        }
    }


    @if $breakpoint == tablet {
        @media only screen and (max-width: $tablet-screen) {
            @content;
        }
    }

    @if $breakpoint == laptop {
        @media only screen and (max-width: $laptop-screen) {
            @content;
        }
    }

    @if $breakpoint == pc {
        @media only screen and (min-width: $pc-screen) {
            @content;
        }
    }
}

@mixin page-default {
    padding-top: $navbar-size;
    background-color: $blanco;
}



.candidate {
    .banner {
        background-image: url("../../../public/img/candidato/banner_empleos.jpg");
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        top: 0;
        min-height: 40rem;
        padding: 0 1.5rem;

        &__title {
            padding: 2.5rem;
            text-align: center;
            display: flex;
            flex-direction: column;

            h1 {
                padding-bottom: 1rem;
            }
        }
    }

    .heading {
        max-width: 1600px;
        margin: 0 auto;
        min-height: 40vh;
        gap: 3rem;

        &__text {
            padding-top: 2rem;
            font-weight: 100;

            &--list {
                padding-top: 0;
            }
        }

        &__title {
            color: $azul-electrico;
            padding-bottom: 2rem;
            text-align: center;

            &--subtitle {
                text-align: left;
            }

            &--green-arteco {
                color: $verde-arteco;
            }
        }

        &__paragraph {
            line-height: 1.75;
            max-width: 65ch;
            padding-bottom: 1.5rem;

            strong {
                font-weight: 900;
            }

            &--index {
                text-align: center;
                padding-bottom: 0;
            }

            &--list {
                padding-bottom: 0;
            }

            &--white {
                color: $blanco;

                strong {
                    color: $verde-arteco;
                }
            }
        }

        &__list {
            &--index {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            &__item {
                cursor: pointer;
                background-color: $gris-claro;
                transition: 0.2s ease-in-out;
                min-height: 14rem;
                &:hover {
                    background-color: $azul-oscuro;

                    p {
                        color: $verde-arteco;
                    }
                }
            }
        }

        &__img {
            display: flex;
            align-items: center;
            padding: 2rem;
        }
    }
}

@include responsive(tablet) {
    .candidate {
        .heading {
            padding: 4rem;
            &__list {
                &__item {
                    min-height: 5rem;
                }
            }
        }

        .separator {
            span {
                height: 15vh !important;
            }

            img {
                object-fit: cover;
            }
        }
    }
}

@include responsive(mobile) {
    .candidate {
        .heading {
            padding: 3rem 1.5rem;

            &__text {
                padding-top: 0;
            }

            &__img {
                padding: 2rem 0;
            }
        }
        .banner {
            text-align: center;

            &__title {
                font-size: 2.5rem;
                padding: 0;
            }
        }
    }
}
